import React, { useState } from "react";
import logo from "../Images/logo.svg";
import "../Styles/Login.css";
import axios from "axios";
import {
    useNavigate,
    Link,
    Routes,
    Route,
    BrowserRouter as Router,
    useParams,
    useLocation
} from "react-router-dom";
import Signup from "./Signup";
import {toast} from "react-toastify";

const ResetPassword = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const email = params.get('email');
    const token = params.get('token');
    const navigate = useNavigate();
    const [newPassword, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL + "/auth/reset-password";

    const handleResetPasswordSubmit = async (e) => {
        e.preventDefault(); // Prevent the page from reloading
        if (!error && newPassword) {
            alert('Passwords match! Form submitted.');
        }
        try {
            const response = await axios.post(apiUrl, {
                email,
                token,
                newPassword,
            });

            toast.success("Password successfully reseted!");
            navigate("/login");
        } catch (error) {
            console.error("Password reset failed:", error);
            toast.error("Password reset failed. Please try again.");
        }
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setError(e.target.value === confirmPassword ? '' : 'Passwords do not match');
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setError(newPassword === e.target.value ? '' : 'Passwords do not match');
    };

    return (

        <div className="login-container">
            <div className="login-box">
                <h1 className="title">Willkommen bei D&D World!</h1>
                <p className="subtitle">
                    Schauen Sie doch einmal in Ihr E-Mail Post Fach!
                </p>
                <form onSubmit={handleResetPasswordSubmit}>
                    <h2 id="Title">Passwort zurücksetzen</h2>
                    <label htmlFor="newPassword">Neues Passwort</label>
                    <input
                        id="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        required
                    />
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                    <button type="submit">Passwort zurücksetzen</button>
                </form>
                <p className="register-text">
                    Doch noch ans Passwort erinnert? <Link to="/Login">Zurück zum Login!</Link>
                </p>
            </div>
        </div>

    );
};

export default ResetPassword;
