import React, { useState } from "react";
import logo from "../Images/logo.svg";
import "../Styles/Login.css";
import axios from "axios";
import {useNavigate, Link, Routes, Route, BrowserRouter as Router} from "react-router-dom";
import Signup from "./Signup";
import {toast} from "react-toastify";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL + "/auth/forgot-password";

    const handlePasswordForgetSubmit = async (e) => {
        e.preventDefault(); // Prevent the page from reloading
        try {
            const response = await axios.post(apiUrl, {
                email,
            });



            if(localStorage.getItem("email") != null) {
                setEmail(localStorage.getItem("email"));
            }

            toast.success("Check your mails!");
        } catch (error) {
            console.error("E-Mail sending failed:", error);
            toast.error("E-Mail sending failed. Please try again.");
        }
    };

    return (

        <div className="login-container">
            <div className="login-box">
                <h1 className="title">Willkommen bei D&D World!</h1>
                <p className="subtitle">
                    Bitte geben Sie Ihre E-Mail ein, um Ihr Passwort zurückzusetzen!
                </p>
                <form onSubmit={handlePasswordForgetSubmit}>
                    <h2 id="Title">Passwort vergessen</h2>
                    <label htmlFor="email">E-Mail</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Email senden</button>
                </form>
                <p className="register-text">
                    Doch noch ans Passwort erinnert? <Link to="/Login">Zurück zum Login!</Link>
                </p>
            </div>
        </div>

    );
};

export default ForgotPassword;
