import React, {useState} from 'react';
import '../Styles/Signup.css';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';

const Signup = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [twoStepsAuthorization, setTwoFA] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL + "/auth/register"

  const handleCheckboxChange = (event) => {
    setTwoFA(event.target.checked); // Update the state based on the checkbox value
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault(); // Prevent the page from reloading
    try {
      const response = await axios.post(apiUrl, {
        username,
        password,
        email,
        twoStepsAuthorization,
      });
      localStorage.setItem("username", username);
      localStorage.setItem("email", email);
      localStorage.setItem("twoStepsAuthorization", twoStepsAuthorization);
      const {token} = response.data;
      localStorage.setItem("token", token);
      toast.success("SignUp successful");
      navigate("/Login");

    } catch (error) {
      console.error("Signup failed:", error);
        toast.error("SignUp failed");
      }
  };

  return (
      <div className="signup-container">
        <div className="signup-box">
          <h1 className="title">Willkommen bei D&D World</h1>
          <p className="subtitle">
            Bitte logge dich ein oder registriere dich, um dein Abenteuer zu beginnen
          </p>

          <form onSubmit={handleRegisterSubmit}>
            <h2 id="Title">Registrieren</h2>

            <label htmlFor="username">Benutzername</label>
            <input
                type="text"
                id="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
            />

            <label htmlFor="email">Email</label>
            <input
                type="email"
                id="email"
                placeholder="EMail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />

            <label htmlFor="password">Passwort</label>
            <input
                type="password"
                id="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />

            <label htmlFor="twoFA">2-Faktor-Authentifizierung</label>
            <input
                type="checkbox"
                id="twoFA"
                value={twoStepsAuthorization}
                onChange={handleCheckboxChange}
            />

            <button type="submit">SignUp</button>
          </form>

          <p className="login-text">
            Du bist bereits ein Abenteurer? <Link to="/Login">Hier einloggen!</Link>
          </p>
        </div>
      </div>
  );
}

export default Signup;
