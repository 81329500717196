import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import '../Styles/global.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SignalRProvider } from "./SignarlRContext";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const Header = React.lazy(() => import("./Header"));
const Homepage = React.lazy(() => import("./Homepage"));
const Login = React.lazy(() => import("./Login"));
const Signup = React.lazy(() => import("./Signup"));
const Maps = React.lazy(() => import("./Maps"));
const Adventures = React.lazy(() => import("./Adventures"));
const ProtectedRoute = React.lazy(() => import("./ProtectedRoute"));
const Chat = React.lazy(() => import("./Chat")); // If used later
const Gameplay = React.lazy(() => import("../Gameplay"));
const Lobby = React.lazy(() => import("./Lobby"));
const TwoFA = React.lazy(() => import("./TwoFA"));
const Character = React.lazy(() => import("./Character"));


function App() {
    return (
        <SignalRProvider>
            <Router>
                <Header/>
                <Routes>
                    <Route path="/" element={<Homepage/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/homepage" element={<Homepage/>}/>
                    <Route path="/maps" element={<Maps/>}/>
                    <Route path="/adventures" element={<ProtectedRoute><Adventures/></ProtectedRoute>}/>
                    <Route path="/lobby/:gameSessionId" element={<ProtectedRoute><Lobby/></ProtectedRoute>}/>
                    <Route path="/game/:gameSessionId" element={<ProtectedRoute><Gameplay/></ProtectedRoute>}/>
                    <Route path="/twoFA" element={<TwoFA/>}/>
                    <Route path="/character" element={<ProtectedRoute><Character/></ProtectedRoute>}/>
                    <Route path="/ForgotPassword" element={<ForgotPassword/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="*" element={<div>404 Not Found</div>} />
                </Routes>
            </Router>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </SignalRProvider>
    );
}

export default App;
